import React from "react"
import Loader from "react-loader-spinner"
import styled, { useTheme } from "styled-components"

const MyLoader = styled(Loader)`
  justify-self: center;
  grid-column: auto;
  @media (min-width: 800px) {
    grid-column: 1/3;
  }
`

const LoaderDots = () => {
  const theme = useTheme()

  return (
    <MyLoader type="ThreeDots" color={theme.action} height={60} width={60} />
  )
}

export default LoaderDots
