import styled from "styled-components"

export const FrontendSubtitle = styled.h2`
  background: -webkit-linear-gradient(50deg, #4e89ae 38%, #ed6663 60%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5vmin;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0;
  padding: 1rem 0;

  @media (max-width: 800px) {
    font-size: 8vmin;
  }
`

export const FrontendDescription = styled.p`
  text-align: center;
  margin-bottom: 1.5rem;
`
export const FrontendDescriptionChallenge = styled.p`
  font-size: 0.75rem;
  color: white;
  margin-bottom: 0.5rem;
`
export const FrontendCard = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 320px;
  min-height: 340px;
  border-radius: 20px;
  box-shadow: 0.6px 0.7px 1px ${({ theme }) => theme.shadow},
    3.3px 3.9px 5.7px -0.4px ${({ theme }) => theme.shadow},
    6.2px 7.3px 10.8px -0.7px ${({ theme }) => theme.shadow},
    10.2px 12px 17.7px -1.1px ${({ theme }) => theme.shadow},
    16.3px 19.1px 28.2px -1.4px ${({ theme }) => theme.shadow},
    25.5px 29.9px 44.2px -1.8px ${({ theme }) => theme.shadow},
    38.8px 45.5px 67.3px -2.1px ${({ theme }) => theme.shadow},
    57.2px 67px 99.1px -2.5px ${({ theme }) => theme.shadow};

  background: ${props => props.bgColor};
  padding: 1rem 0.7rem;
  transition: 0.5s;

  &:hover {
    transform: translateY(-0.5rem);
  }

  @media (max-width: 320px) {
    width: 280px;
    height: 360px;
  }
`

export const FrontendCartTitle = styled.h3`
  font-size: 1.05rem;
  margin: 0 0 0.2rem 0;
  color: white;
`
export const FrontendHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  align-items: center;
`
export const FrontendActions = styled.div`
  display: flex;
  margin-top: 0.2rem;
  align-items: center;
  justify-items: center;

  align-self: flex-end;
`
