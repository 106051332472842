import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { ButtonMinimal } from "../Buttons"
import LoaderDots from "./loader"
import {
  FrontendActions,
  FrontendCard,
  FrontendCartTitle,
  FrontendDescription,
  FrontendDescriptionChallenge,
  FrontendHeader,
  FrontendSubtitle,
} from "./styled"

const FrontendChallenge = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProject(sort: { order: DESC, fields: createdAt }) {
        nodes {
          id
          isChallenge
          color
          title
          demo
          repo
          description
          stack {
            tech
          }
          preview {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 60
              width: 350
            )
          }
          createdAt
        }
      }
    }
  `)

  const codingChallenge = data.allContentfulProject.nodes

  const [infinite, setInfinite] = useState(codingChallenge.slice(0, 2))
  const [hasMore, setHasMore] = useState(true)
  const [lastChallenge, setLastChallenge] = useState(2)

  const fetchMoreData = () => {
    if (infinite.length >= codingChallenge.length) {
      setHasMore(false)
      return
    }
    setTimeout(() => {
      setInfinite(
        infinite.concat(codingChallenge.slice(lastChallenge, lastChallenge + 2))
      )
      setLastChallenge(lastChallenge + 2)
    }, 1500)
  }

  return (
    <>
      <FrontendSubtitle>Challenges</FrontendSubtitle>
      <FrontendDescription>
        Los challenges son de diversos sitios como Frontend Mentor,
        DevChallenges.io
      </FrontendDescription>

      <InfiniteScroll
        dataLength={infinite.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<LoaderDots />}
        scrollThreshold={0.9}
        endMessage={
          <p style={{ textAlign: "center", alignSelf: "center" }}>
            Eso es todo
          </p>
        }
        style={{
          overflow: "initial",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          rowGap: "2.8rem",
          columnGap: "1rem",
          justifyItems: "center",
        }}
      >
        {infinite.map((project, index) => {
          const image = getImage(project.preview)
          return (
            <FrontendCard key={project.id} bgColor={`#${project.color}`}>
              <GatsbyImage
                image={image}
                alt={project.preview.title}
                imgStyle={{
                  borderRadius: "5px",
                }}
                style={{ marginBottom: "0.1rem", minHeight: "160px" }}
              />
              <FrontendHeader>
                <FrontendCartTitle>{project.title}</FrontendCartTitle>
                <FrontendDescriptionChallenge>
                  {project.description}
                </FrontendDescriptionChallenge>
              </FrontendHeader>
              <FrontendActions>
                <ButtonMinimal text="Readme" url={project.repo} />
                <ButtonMinimal text="Demo" url={project.demo} />
              </FrontendActions>
            </FrontendCard>
          )
        })}
      </InfiniteScroll>
    </>
  )
}

export default FrontendChallenge
